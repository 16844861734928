import React, { useMemo, useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import styled from '@emotion/styled';
import { Tabs } from 'components';
import EditAccount from './EditAccount';
import Listings from './Listings';
import Offers from './Offers';
import { ProfileContextWrapper } from './context';

type TabKey = 'account' | 'listings' | 'offers';

type Tab = { key: TabKey; text: string; };

const tabs: Tab[] = [
  { key: 'account', text: 'Edit Account' },
  { key: 'listings', text: 'Listings' },
  { key: 'offers', text: 'Offers' }
];

const ProfilePageWrapper = () => {
  const [activeTab, setActiveTab] = useQueryParam('tab', StringParam);

  useEffect(() => {
    if (!activeTab) setActiveTab('account');
  }, [activeTab, setActiveTab]);

  const activeTabToShow = useMemo(() => {
    switch (activeTab) {
      case 'listings':
        return <Listings />;
      case 'offers':
        return <Offers />;
      default:
        return <EditAccount />;
    }
  }, [activeTab]);

  return (
    <StyledProfilePage className="profile">
      <div className="profile__header">
        <h1 className="profile__title">Profile</h1>
        <StyledTabs
          activeTab={String(activeTab)}
          onSelect={(_e, value) => setActiveTab(value.key)}
          items={tabs}
        />
      </div>
      {activeTabToShow}
    </StyledProfilePage>
  );
};

const ProfilePage = () => (
  <ProfileContextWrapper>
    <ProfilePageWrapper />
  </ProfileContextWrapper>
);

export default ProfilePage;

const StyledProfilePage = styled.div`
  display: flex;
  max-width: 1157px;
  padding: 0 16px;
  margin: 32px auto;
  flex-direction: column;

  .profile__header {
    margin: 0 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .profile__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    margin: 0;
  }
  .profile__card-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 12px;
  }

  .profile-wrapper {
    display: grid;
    grid-template-areas:
      'account logout'
      'password logout';
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 42px;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }
  .profile-section {
    margin: 0 0 36px;
    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 24px;
      padding: 0 0 5px;
      border-bottom: 1px solid ${props => props.theme.colors.borderColor};
    }
  }
  .profile__link-download {
    cursor: pointer;
  }
  .profile-card {
    display: flex;
    width: 100%;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .profile-wrapper {
      max-width: 570px;
      width: 100%;
      display: flex;
    }
    .profile-logout {
      order: 1;
    }
    .profile-section__btn {
      width: 100%;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .profile__header {
      margin: 0 0 16px;
    }
    .profile__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

const StyledTabs = styled(Tabs)`
  width: max-content;
  padding: 4px;
  background: ${props => props.theme.colors.creamyGray};
  border-radius: ${props => props.theme.misc.borderRadius};

  .tab-item {
    margin: 0 4px 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #000;
    transition: none;
    border: 0;
    height: 24px;
    padding: 0 8px;

    &.active {
      color: #fff;
      background: #000;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &:last-of-type {
      margin: 0;
    }
  }
`;
