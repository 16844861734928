import React, { useState, useRef, useEffect, useMemo } from 'react';
import MaskedInput from 'react-input-mask';
import { useForm, Controller } from 'react-hook-form';
import notify from 'notify';
import styled from '@emotion/styled';

import { updateProfile } from 'apis/users';
import { tryGetFirstError } from 'utils/requests';
import { Button, Icon, Input } from 'components';
import avatarPlaceholder from './avatarPlaceholder.svg';

interface AccountSettingsProps {
  profile: Profile | null;
  setProfile: React.Dispatch<React.SetStateAction<Profile | null>>;
}

const allowedImageTypes = new Set(['image/png', 'image/jpg', 'image/jpeg', 'image/gif']);

const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};

const AccountSettings: React.FC<AccountSettingsProps> = ({ profile, setProfile }) => {
  const { register, control, errors, getValues, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      email: profile?.email,
      phone: profile?.phone || undefined,
      avatar: profile?.avatar
    },
    mode: 'onChange'
  });
  const [avatar, setAvatar] = useState<string | null | undefined>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setAvatar(profile?.avatar);
  }, [profile]);

  const fullName = useMemo(() => {
    if (!profile?.firstName && !profile?.lastName) return null;
    return <h5 className="user-name">{`${profile?.firstName} ${profile?.lastName}`}</h5>;
  }, [profile]);

  if (!profile) return null;

  const uploadNewAvatar = async (file: File) => {
    try {
      const { avatar } = await updateProfile({ avatar: file });
      const newSrc = await getBase64(file);
      setAvatar(newSrc);
      setProfile({ ...profile, avatar } as any);
      notify('Avatar updated');
    } catch (err) {
      notify('Failed to upload new avatar');
    }
  };

  const handleFileInputChange = e => {
    const file: File = e.target.files[0];
    if (!file) return;
    if (!allowedImageTypes.has(file.type)) {
      notify('Only png/jpg/jpeg/gif files are allowed');
      return;
    }
    fileInputRef.current!.value = '';
    uploadNewAvatar(file);
  };

  const openAvatarUpload = () => {
    fileInputRef.current!.click();
  };

  const submit = async values => {
    try {
      await updateProfile({
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        // wrong type?
        avatar
      });
      reset(getValues());
      notify('Profile changes saved');
    } catch (err) {
      reset();
      notify(tryGetFirstError(err) || err.message);
    }
  };

  return (
    <div className="profile-section profile-account">
      <h3 className="profile-section__title">Edit Account</h3>
      <StyledProfileInfo className="profile-info">
        <div className="user-avatar">
          <img src={avatar || avatarPlaceholder} alt="user-avatar-image" />
          <div
            className="update-avatar"
            role="button"
            onClick={openAvatarUpload}
            data-cy="update_avatar"
            tabIndex={0}>
            <Icon name="camera" className="update-avatar__icon" />
            <span className="update-avatar__text">Update</span>
          </div>
          <input
            ref={fileInputRef}
            accept="image/*"
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileInputChange}
            data-cy="input_file_avatar"
          />
        </div>
        <div className="user-info">
          {fullName}
          <a href={`mailto:${profile.email}`} className="user-email">
            {profile.email}
          </a>
        </div>
      </StyledProfileInfo>
      <form onSubmit={handleSubmit(submit)}>
        <div className="profile-section__input-wrapper">
          <Input
            ref={register({
              required: 'Required'
            })}
            name="firstName"
            label="First Name"
            placeholder="First Name"
            error={errors.firstName}
            className="profile-section__input"
          />
          <Input
            ref={register({
              required: 'Required'
            })}
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            error={errors.lastName}
            className="profile-section__input"
          />
          <Input
            ref={register()}
            name="email"
            label="Email"
            disabled
            className="profile-section__input"
          />
          <Controller
            control={control}
            name="phone"
            render={controllerProps => (
              <Input
                as={MaskedInput}
                mask="+1 (999) 999-9999"
                type="tel"
                error={errors.phone}
                {...controllerProps}
                label="Phone"
                placeholder="(XXX) XXX-XXXX"
                className="profile-section__input"
              />
            )}
          />
        </div>
        <Button primary type="submit" className="profile-section__btn">
          Save
        </Button>
      </form>
    </div>
  );
};

export default AccountSettings;

const StyledProfileInfo = styled.div`
  display: flex;
  align-items: start;
  margin: 0 0 36px;
  width: 100%;

  .user-avatar {
    width: 120px;
    height: 120px;
    border-radius: ${props => props.theme.misc.borderRadius};
    margin: 0 24px 0 0;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    outline: none;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .user-info {
    overflow: hidden;
  }

  .user-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 10px;
  }
  .user-email {
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.colors.etherealGrey};
  }
  .user-email {
    display: inline-block;
    text-decoration: none;
    max-width: 100%;
    word-break: break-all;
  }
  .update-avatar {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: ${props =>
      `0 0 ${props.theme.misc.borderRadius} ${props.theme.misc.borderRadius}`};
    &__icon {
      margin: 0 8px 0 0;
    }
    &__text {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: white;
    }
  }
`;
