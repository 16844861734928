import React, { useState, useEffect } from 'react';
import notify from 'notify';

import { getProfile } from 'apis/users';
import useAuthContext from 'hooks/useAuthContext';

interface ProfileContextValues {
  profile: Profile | null;
  setProfile: React.Dispatch<React.SetStateAction<Profile | null>>;
  logout: () => void;
}

const ProfileContext = React.createContext({} as ProfileContextValues);

export const ProfileContextWrapper = ({ children }) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const { logout, logoutManually } = useAuthContext();

  const _getProfile = async () => {
    try {
      const data = await getProfile();
      setProfile(data);
    } catch (err) {
      notify(err.message);
    }
  };

  const _logout = async () => {
    try {
      await logout();
      notify('Logged out');
    } catch (err) {
      logoutManually();
      notify(err.message);
    }
  };

  useEffect(() => {
    _getProfile();
  }, []);

  const contextValues = {
    profile,
    setProfile,
    logout: _logout
  };

  return <ProfileContext.Provider value={contextValues}>{children}</ProfileContext.Provider>;
};

export default ProfileContext;
