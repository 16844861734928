import React, { useContext } from 'react';
import { Link } from 'gatsby';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { Image } from 'components';
import noImage from 'assets/images/placeholder_no_image.jpg';
import { IMAGE_SIZES } from 'consts';
import useLayout from 'hooks/useLayout';
import { getAddress, getListingUrl } from 'utils/listing';
import { formatCurrency } from 'utils/rifmFormatters';
import ListingContainer from './ListingContainer';
import ProfileContext from './context';

interface OffersProps extends React.HTMLAttributes<HTMLDivElement> {
  path?: string;
}

const Offers: React.FC<OffersProps> = () => {
  const layout = useLayout();
  const { profile } = useContext(ProfileContext);
  const offers = profile?.offers;
  const isMobile = layout === 'mobile';

  if (!offers?.length) return null;

  const getOfferUrl = data => {
    if (!data.official_offer?.length) return null;
    return data.official_offer?.[0].url;
  };

  return (
    <>
      <h3 className="profile-section__title">Your Offers</h3>
      {offers?.map(({ listing, formData, ...item }) => {
        return (
          listing && (
            <ListingContainer key={item.id}>
              <div className="profile-card">
                {listing.images?.[0] ? (
                  <Image
                    cloudFront={{ size: IMAGE_SIZES.medium, key: listing.images[0] }}
                    alt="mini-propetry"
                    className="profile__photo"
                  />
                ) : (
                    <img src={noImage} alt="mini-propetry" className="profile__photo" />
                  )}
                <div className="profile__details">
                  <div className="profile__top">
                    <h3 className="profile__card-title">
                      {listing.address1}, {getAddress(listing)}
                    </h3>
                    <div className={clsx("profile__status", [item.status?.toLocaleLowerCase()])}>{item.status}</div>
                  </div>

                  <div className="profile__info">
                    <span className="profile__info-label">Offer Price:</span>{' '}
                    {formatCurrency(formData.offerAmount)}
                  </div>
                  <div className="profile__info">
                    <span className="profile__info-label">Offer Date:</span>{' '}
                    {dayjs.unix(item.created).format('MM/DD/YYYY')}
                  </div>
                  {!isMobile && (
                    <div className="profile__btn-group">
                      <Link
                        className="profile__btn-link"
                        to={getListingUrl({ id: item.listingId, address: listing.address1 })}>
                        Check Listing
                      </Link>
                      <a
                        className="profile__link profile__link-download"
                        href={item.link}
                        download
                        target="_blank"
                        rel="noreferrer">
                        Download Offer
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {isMobile && (
                <div className="profile__btn-group">
                  <Link
                    className="profile__btn-link"
                    to={getListingUrl({ id: item.listingId, address: listing.address1 })}>
                    Check Listing
                  </Link>
                  <a
                    className="profile__link profile__link-download"
                    href={getOfferUrl(item)}
                    download
                    target="_blank"
                    rel="noreferrer">
                    Download Offer
                    </a>
                </div>
              )}
            </ListingContainer>
          )
        );
      })}
    </>
  );
};

export default React.memo(Offers);
